import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from '@/router/routes'
import config from '@/config'
import { getStore, setStore } from '@/utils/store'
import { getFirstEnterPath, setFirstEnterPath } from '@/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  scrollBehavior: () => ({ y: 0 }),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = getPageTitle(to.meta && to.meta.title)
  next()
})

router.afterEach((to, from) => {
  // 当页面为打开的第一个页面时，url添加进sessionStorage
  let path = to.path
  if (!getFirstEnterPath()) {
    setFirstEnterPath(path)
  }
})

export default router

function getPageTitle(pageTitle) {
  if (pageTitle) {
    return `${pageTitle} - ${config.title}`
  }
  return `${config.title}`
}
