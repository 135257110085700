import Layout from '@/layout/index.vue'

const routes = [
  {
    path: '/',
    redirect: '/',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'QueryOrder',
        meta: {
          title: '', 
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/queryOrder')
      },
      {
        path: '/photoUpload',
        name: 'PhotoUpload',
        meta: {
          title: '照片上传', 
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/photoUpload')
      },
      {
        path: '/editPhotos',
        name: 'EditPhotos',
        meta: {
          title: '编辑照片', 
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/editPhotos')
      },  
      {
        path: '/editImgs',
        name: 'EditImgs',
        meta: {
          title: '编辑照片', 
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/editImgs')
      },

      {
        path: '/paySuccess',
        name: 'PaySuccess',
        meta: {
          title: '提交订单', 
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/paySuccess')
      },

      

 
      {
        path: '/home',
        name: 'Home',
        meta: {
          title: '首页',
          tabbar: true,
          hideNavBar: true
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/home')
      },
      
      
      {
        path: '/user',
        name: 'User',
        meta: {
          title: '用户',
          tabbar: true,
          hideNavBar: true
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/user')
      },
      {
        path: '/setting',
        name: 'Setting',
        meta: {
          keepAlive: true,
          title: '设置'
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/setting')
      },
      {
        path: '/address',
        name: 'Address',
        meta: {
          title: '地址'
        },
        component: () => import(/* webpackChunkName: "about" */ '@/views/address')
      }
    ]
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "about" */ '@/views/error/404'),
    meta: {
      title: '404',
      isHide: true
    }
  }
]

export default routes
