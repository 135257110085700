const config = {
  //标题
  title: 'pdd照片',
  // baseUrl: 'https://proxy.pddphoto.cn/',
  // baseUrl: 'http://47.109.19.246:8000/',
  baseUrl: 'https://p.pddphoto.cn/',
  // baseUrl: 'http://192.168.5.145:8001/',
  designWidth: 750,
  whitePathList: []
}

export default config
