import App from '@/App.vue';
import * as filters from '@/filters'; // 全局filter
import '@/plugins';
import router from '@/router';
import store from '@/store';
import '@/styles/index.scss';
import Vue from 'vue';

Vue.config.productionTip = false

import fabric from 'fabric';
import { Lazyload } from 'vant';

Vue.use(Lazyload);
Vue.use(fabric);

// 加载过滤器
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
