export default {
  namespaced: true,
  state: {
    menuList: [
      { title: '首页', path: '/home' },
      { title: '公司历程', path: '/company' },
      { title: '产品中心', path: '/product' },
      { title: '团长加盟', path: '/join' },
      { title: '供应链招商', path: '/supplier' },
      { title: '关于我们', path: '/about' }
    ]
  }
}
