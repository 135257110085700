<template>
  <div class="layout"> 
    <router-view/> 
  </div>
</template>

<script>

export default {
  components: {},
  name: 'layout',
  data() {
    return {
       
    }
  },
  computed: {
    
  },

  watch: {
    // $route: {
    //   deep: true,
    //   immediate: true,
    //   handler() {
    //     this.setActive()
    //   }
    // }
  },
  methods: { 
  }, 
  mounted() {},
  created() {}
}
</script>

<style scoped lang="scss">
.layout {
  
}
</style>
